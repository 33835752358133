import React from 'react'

import { IdentityVerified } from '../components/modals'

class IdentityVerifiedPage extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: '#f5f5f5' }}>
        <IdentityVerified />
      </div>
    )
  }
}

export default IdentityVerifiedPage
